import CryptoJS from 'crypto-js'

// md5加密
export const md5 = (str) => {
	const hash = CryptoJS.MD5(str);
	return hash.toString(CryptoJS.enc.Hex);
}

// md5 base64加密
export const md5_base64 = (input) => {
	const md5Hash = CryptoJS.MD5(input);
	return md5Hash.toString(CryptoJS.enc.Base64);
}

// sha1加密
export const sha1 = (str) => {
	const sha1Hash = CryptoJS.SHA1(str);
	return sha1Hash.toString(CryptoJS.enc.Hex);
}

// sha1 base64加密
export const sha1_base64 = (input) => {
	const sha1Hash = CryptoJS.SHA1(input);
	return sha1Hash.toString(CryptoJS.enc.Base64);
}

// ase 加密
export const encrypt_ase = (data, password = null) => {
	let key = password ? CryptoJS.enc.Utf8.parse(password) : get_ase_key()
	let iv = get_ase_iv()

	data = typeof data == 'object' ? JSON.stringify(data) : data
	let encrypted = CryptoJS.AES.encrypt(data, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
	return encrypted.toString()
}

// ase 解密
export const decrypt_ase = (ciphertext, password = null) => {
	let key = password ? CryptoJS.enc.Utf8.parse(password) : get_ase_key()
	let iv = get_ase_iv()

	let bytes = CryptoJS.AES.decrypt(ciphertext, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 })
	let data = bytes.toString(CryptoJS.enc.Utf8)
	if (data) {
		return is_str_ocject(data) ? JSON.parse(data) : data
	}
}

// 获取ase key
export const get_ase_key = (tolerance = 86400) => {
	let time = Math.floor(Date.now() / 1000 / tolerance) * tolerance + 365
	let sh1Text = sha1(time.toString())
	return CryptoJS.enc.Utf8.parse(sh1Text)
}

// 获取ase VI
export const get_ase_iv = (tolerance = 86400) => {
	let time = Math.floor(Date.now() / 1000 / tolerance) * tolerance - 365
	let sh1Text = sha1(time.toString())
	return CryptoJS.enc.Utf8.parse(sh1Text)
}

// 判断是否是json
export const is_str_ocject = (str) => {
	if (typeof str == 'string') {
		try {
			var obj = JSON.parse(str)
			if (typeof obj == 'object' && obj) {
				return true
			} else {
				return false
			}
		} catch (e) {
			return false
		}
	}
	return false
}
