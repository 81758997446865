import Vue from 'vue'
import { mapGetters } from 'vuex';
import io from 'socket.io-client';
import VueSocketIO from 'vue-socket.io'
import { socketURL, msgEncode } from '@/config'
import myParser from './socket.io-json-parser'

Vue.use(new VueSocketIO({
	debug: false,
	connection: io(socketURL, {
		parser: msgEncode ? myParser : null,
		autoConnect: false, // 自动连接 
		transports: ['websocket', 'polling'], // 传输方式
		auth: {
			client: 'website',
		}
	}),
}))

// 3. 注入组件选项
Vue.mixin({
	computed: {
		...mapGetters(["weather", "aerators", "monitors", "feeders", "generators", "serverTime", "token"]),
	},
	filters: {

	},
	methods: {
		getTimeDiff(time1) {

			console.log(new Date(), time1);

			let diff = new Date() - new Date(time1);
			return diff;
		},
		formatDatetime(time) {
			time = time ? time : new Date().getTime();
			const now = new Date(time);
			const year = now.getFullYear();
			const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份是从0开始的
			const day = now.getDate().toString().padStart(2, '0');
			const hours = now.getHours().toString().padStart(2, '0');
			const minutes = now.getMinutes().toString().padStart(2, '0');
			const seconds = now.getSeconds().toString().padStart(2, '0');

			return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
		},
		emit(event, data) {
			// data = ase_encrypt(data);
			this.$socket.emit(event, data);
		}
	}
});