const Emitter = require("component-emitter");
const { encrypt_ase, decrypt_ase } = require('./crypt');


const PacketType = {
	CONNECT: 0,
	DISCONNECT: 1,
	EVENT: 2,
	ACK: 3,
	CONNECT_ERROR: 4,
};

const isInteger = Number.isInteger || function (value) {
	return (
		typeof value === "number" &&
		isFinite(value) &&
		Math.floor(value) === value
	);
};

class Encoder {
	constructor() {
		this.protocol = 5;
	}
	encode(packet) {
		packet = encrypt_ase(packet);
		return [packet];
	}
}

class Decoder extends Emitter {
	add(packet) {
		let decoded = decrypt_ase(packet);
		this.checkPacket(decoded);
		this.emit("decoded", decoded);
	};

	checkPacket(decoded) {
		var isTypeValid =
			isInteger(decoded.type) &&
			decoded.type >= PacketType.CONNECT &&
			decoded.type <= PacketType.CONNECT_ERROR;
		if (!isTypeValid) {
			throw new Error("invalid packet type");
		}

		if (!this.isString(decoded.nsp)) {
			throw new Error("invalid namespace");
		}

		if (!this.isDataValid(decoded)) {
			throw new Error("invalid payload");
		}

		var isAckValid = decoded.id === undefined || isInteger(decoded.id);
		if (!isAckValid) {
			throw new Error("invalid packet id");
		}
	}

	isDataValid(decoded) {
		switch (decoded.type) {
			case PacketType.CONNECT:
				return decoded.data === undefined || this.isObject(decoded.data);
			case PacketType.DISCONNECT:
				return decoded.data === undefined;
			case PacketType.CONNECT_ERROR:
				return this.isString(decoded.data) || this.isObject(decoded.data);
			default:
				return Array.isArray(decoded.data);
		}
	}

	isObject(value) {
		return Object.prototype.toString.call(value) === "[object Object]";
	}

	isString(value) {
		return typeof value === "string";
	};

	destroy() { };
}

exports.Encoder = Encoder;
exports.Decoder = Decoder;