import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layouts'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: Layout,
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('@/views/home'),
        meta: {
					title: '鱼自乐园',
          keepAlive: false,
        },
      },
			{
				path: 'monitor',
				name: 'Monitor',
				component: () => import('@/views/monitor'),
				meta: {
					title: '数据监测',
					keepAlive: false,
				},
			},

			{
				path: 'feeder',
				name: 'Feeder',
				component: () => import('@/views/feeder'),
				meta: {
					title: '鱼自乐园',
					keepAlive: false,
				},
			},

      {
				path: 'aerator',
				name: 'Aerator',
				component: () => import('@/views/aerator'),
        meta: {
					title: '鱼自乐园',
          keepAlive: false,
        },
      },

			{
				path: 'generator',
				name: 'Generator',
				component: () => import('@/views/generator'),
				meta: {
					title: '鱼自乐园',
					keepAlive: false,
				},
			},
			
			{
				path: 'pump',
				name: 'pump',
				component: () => import('@/views/pump'),
				meta: {
					title: '鱼自乐园',
					keepAlive: false,
				},
			},
			{
				path: 'weather',
				name: 'weather',
				component: () => import('@/views/weather'),
				meta: {
					title: '气象站',
					keepAlive: false,
				},
			},
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
    meta: {
			title: '鱼自乐园-登陆',
      keepAlive: false,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})


export default router
