import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import storage from './utils/storage'
import axios from "./utils/request";
import VueAxios from 'vue-axios';

import './plugins'
import './utils/mixins'

Vue.use(VueAxios, axios);


Vue.config.productionTip = false
Vue.prototype.$session = storage.session;
Vue.prototype.$local = storage.local;

new Vue({
	router,
	store,
	data() {
		return {
			timer: null,
		}
	},
	render: (h) => h(App),
	mounted() {
		this.checkLogin();
	},
	methods: {
		// 检测是否登录，并且登录是否过期
		checkLogin() {
			this.axios.get('/checkLogin').then((res) => {
				this.removeLoading();
				if (res.success) {
					this.$store.commit('basic', { 'key': 'identity', 'value': res.identity })
					this.$store.commit('basic', { 'key': 'token', 'value': this.$local.get('token') })
					this.$socket.io.opts.auth.token = this.$local.get('token')
					// console.log(this.$socket.io);
					this.$socket.open();
				} else {
					this.logout();
				}
			})
		},
		// 退出登录
		logout() {
			this.$local.set("token", "")
			this.$local.set("identity", "")
			this.$store.commit('basic', { 'key': 'identity', 'value': {} })
			this.$store.commit('basic', { 'key': 'token', 'value': "" })
			this.$socket.close();
			if (this.$route.path != '/login') {
				this.$router.push('/login')
			}
		},
		// 增氧机-开-关
		switchAerator(data) {
			this.emit('switch-aerator', data);
		},
		// 投料机-开-关
		switchFeeder(data) {
			this.emit('switch-feeder', data);
		},
		// 水泵-开-关
		switchPump(data) {
			this.emit('switch-pump', data);
		},
		// 发电机-开-关
		switchGenerator(data) {
			this.emit('switch-generator', data);
		},
		removeLoading() {
			let loadingNode = document.getElementById('app-loading');
			if (loadingNode) {
				loadingNode.remove();
			}
		},
	},
	sockets: {
		// 连接成功
		connect() {
			this.emit("login", { id: this.$store.state.identity._id });
		},
		// 登录过期
		logout(data) {
			if (this.$route.path != '/login') {
				this.$toast.fail(data.message);
			}
			this.logout();
		},
		// 刷新页面
		refresh() {
			location.href = window.location.href;
		},
		// 获取时间
		time(data) {
			clearInterval(this.timer);
			this.timer = setInterval(() => {
				let time = this.formatDatetime(data.time);
				this.$store.commit('basic', {
					key: 'serverTime',
					value: time
				})
				data.time += 1000;
			}, 999);
		},
		// 获取增氧机数据及监测数据
		aerators(data) {
			// console.log(data);
			this.$store.commit('basic', {
				key: 'aerators',
				value: data
			})
		},
		// 获取监测数据
		monitors(data) {
			// console.log(data);
			this.$store.commit('basic', {
				key: 'monitors',
				value: data
			})
		},
		// 获取投料机数据
		feeders(data) {
			// console.log(data);
			this.$store.commit('basic', {
				key: 'feeders',
				value: data
			})
		},
		// 获取发电机数据
		generators(data) {
			// console.log(data);
			this.$store.commit('basic', {
				key: 'generators',
				value: data
			})
		},
		// 获取天气
		weather(data) {
			this.$store.commit('basic', {
				key: 'weather',
				value: data
			})
		},
		test(data) {
			console.log(data);
		}
	}
}).$mount('#app')