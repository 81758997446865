const getters = {
	weather: state => state.weather,
	aerators: state => state.aerators,
	monitors: state => state.monitors,
	feeders: state => state.feeders,
	generators: state => state.generators,
	serverTime: state => state.serverTime,
	token: state => state.token
}
export default getters
