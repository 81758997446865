// 按需全局引入 vant组件
import Vue from 'vue'
import { Button } from 'vant'
import { Form } from 'vant'
import { Field } from 'vant'
import { Checkbox } from 'vant'
import { Cell, CellGroup } from 'vant'
import { Tabbar, TabbarItem } from 'vant'
import { Image as VanImage } from 'vant'
import { Switch } from 'vant'
import { NavBar, Dialog } from 'vant'
import { CheckboxGroup } from 'vant'
import { Icon } from 'vant';
import { Popup } from 'vant';
import { Stepper } from 'vant';

Vue.use(Stepper);
Vue.use(Popup);
Vue.use(Icon);
Vue.use(CheckboxGroup)
Vue.use(NavBar)
Vue.use(Switch)
Vue.use(VanImage)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Checkbox)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(Button)
Vue.use(Dialog)
