import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		token: '',
		weather: {},
		aerators: [],
		monitors: [],
		feeders: [],
		generators: [],
		serverTime: '-',
	},
	mutations: {
		basic(state, data) {
			state[data.key] = data.value;
		}
	},
	getters
})

export default store