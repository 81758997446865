import axios from 'axios'
import {
	Toast
} from 'vant'
import storage from '@/utils/storage'
import {
	tansParams
} from "@/utils/index";
import store from '../store'

import { apiURL } from '@/config'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
	// 默认请求地址
	baseURL: apiURL,
	// 超时时间
	timeout: 1000 * 60
})

// request拦截器
service.interceptors.request.use(config => {
	// 是否需要设置token
	const needToken = true
	// 是否需要防止数据重复提交
	const preventRepeatSubmit = true
	// 让每个请求携带token，请根据实际情况自行修改
	if (needToken && storage.local.get("token")) {
		config.headers['token'] = storage.local.get("token")
	}
	config.headers['language'] = store.state.locale;

	// console.log(config);

	// 防止数据重复提交
	if (preventRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
		const requestObj = {
			url: config.url,
			data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
			time: new Date().getTime()
		}
		const sessionObj = storage.session.getJSON('sessionObj')
		if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
			storage.session.setJSON('sessionObj', requestObj)
		} else {
			const s_url = sessionObj.url; // 请求地址
			const s_data = sessionObj.data; // 请求数据
			const s_time = sessionObj.time; // 请求时间
			const interval = 1000; // 间隔时间(ms)，小于此时间视为重复提交
			if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
				const message = '数据正在处理，请勿重复提交';
				console.warn(`[${s_url}]: ` + message)
				return Promise.reject(new Error(message))
			} else {
				storage.session.setJSON('sessionObj', requestObj)
			}
		}
	}
	// get请求映射params参数
	if (config.method === 'get' && config.params) {
		let url = config.url + '?' + tansParams(config.params);
		url = url.slice(0, -1);
		config.params = {};
		config.url = url;
	}

	return config
}, error => {
	console.warn(error)
	Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
	// 状态码错误提示
	const errorCode = {
		'401': '认证失败，无法访问系统资源',
		'403': '当前操作没有权限',
		'404': '访问资源不存在',
		'default': '系统未知错误，请反馈给管理员'
	}
	// 未设置状态码则默认成功状态
	const code = res.data.code || 200;

	// 获取错误信息
	const message = errorCode[code] || res.data.msg || errorCode['default']
	// 二进制数据则直接返回数据
	if (res.request.responseType === 'blob' || res.request.responseType === 'arraybuffer') {
		return res.data
	}
	if (res.status != 200) {
		// 请求失败，提示错误信息
		Toast({
			message: message,
			type: 'fail'
		})
		return Promise.reject(message)
	} else {
		// 请求成功，返回数据
		return res.data
		// console.log('res.data', res.data);
		// return Promise.reject(res.data)
	}
}, error => {
	console.warn(error)
	let {
		message
	} = error;
	if (message == "Network Error") {
		message = "后端接口连接异常";
	} else if (message.includes("timeout")) {
		message = "系统接口请求超时";
		// message = "正在同步区块，请刷新页面";
	} else if (message.includes("Request failed with status code")) {
		message = "系统接口" + message.substr(message.length - 3) + "异常";
	}
	Toast({
		message: message,
		type: 'fail'
	})
	return Promise.reject(error)
})

export default service
