/**
 * @Description: 路由守卫
 * @author TK
 * @date 2022-05-31 13:53:17
 */
import router from '@/router'
import getPageTitle from '@/utils/pageTitle'

router.beforeResolve(async (to, from, next) => {
	document.title = getPageTitle(to.meta.title)
	next();
})
router.afterEach(() => {
	// 
})
